import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.css'
import './index.scss'
import './theme/theme.scss'

import App from './App'

ReactDOM.render(
  // Commented to avoir antd warnings. Recommended uncomment for development
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
