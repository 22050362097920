import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import moment from 'moment'

import apiEndpoints from '../../config/apiEndpoints'
import axiosApi from '../../config/axiosApiInterceptor'
import ReportsLayout from '../../layouts/ReportsLayout/ReportsLayout'
import { columns } from './PayReports.definitions'

import './PayReports.scss'

interface Report {
  'id': number,
  'estado': string
  'usuario': string
  'fecha_creacion': string
  'nombre': string
  'total': number,
  'pendientes': number,
  'conciliadas': number,
  'no_conciliadas ': number,
}

const PayReports = function () {
  const [reportsResponse, setReportsResponse] = useState<any>({ count: 10, results: [] })
  const [filteredReports, setFilteredReports] = useState<Report[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedReports, setSelectedReports] = useState<any[]>()
  const [reportsPageUrl, setReportsPageUrl] = useState<string>(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.REPORTES_DE_PAGO.REPORTE}`)

  const getReports = () => {
    axiosApi.get(reportsPageUrl, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        if (sessionStorage.getItem('access_token')) {
          if (response.status === 200) {
            setReportsResponse(response.data)
          }
        }
        setLoading(false)
      })
      .catch((error) => { setLoading(false) })
  }

  const search = (value: any, column: any) => {
    const filteredReports = reportsResponse.results.filter((row: any) => String(row[column]).toLowerCase().includes(value.toLowerCase()))
    setFilteredReports(filteredReports)
  }

  const downloadReports = () => {
    if (selectedReports && selectedReports.length !== 0) {
      axiosApi.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.REPORTES_DE_PAGO.DESCARGA_REPORTES}${JSON.stringify(selectedReports).replace(/[[\]]/g, '')}/`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
        responseType: 'blob',
      })
        .then((response) => {
          const blob = new Blob(
            [response.data],
            { type: response.headers['content-type'] },
          )
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          if (response.headers['content-disposition']) {
            a.download = response.headers['content-disposition'].split('filename=')[1]
          } else {
            a.download = `reportes_${moment().format('DD_MM_YYYY_hh_mm_ss')}.xlsx`
          }
          a.click()
        })
        .catch((error) => { })
    }
  }

  function getReportDeamon() {
    getReports()
    const interval = setInterval(() => {
      getReports()
    }, 5000)
    return () => clearInterval(interval)
  }

  useEffect(getReportDeamon, [reportsPageUrl])

  return (
    <ReportsLayout section="PayReports">
      <div className="PayReports">
        <h1>Reportes de Pagos Facturas</h1>
        <div className="download-button">
          <Button type="primary" onClick={downloadReports}>
            Descargar
          </Button>
        </div>
        <Table
          loading={loading}
          rowClassName={(_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
          rowSelection={{
            getCheckboxProps: (record) => ({
              disabled: ['Pendiente', 'Validando', 'Procesando', 'Validando', 'Archivo inválido'].includes(record.estado),
              name: record.name,
            }),
            onChange: (selectedReports) => {
              setSelectedReports(selectedReports)
            },
            type: 'checkbox',
          }}
          columns={columns(search)}
          rowKey="id"
          dataSource={filteredReports || reportsResponse.results}
          pagination={{
            onChange: (page, pageSize) => {
              setReportsPageUrl(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.REPORTES_DE_PAGO.REPORTE}?page=${page}`)
            },
            pageSize: 10, // Seria ideal que este dato llegara del backend
            total: reportsResponse.count,
          }}
        />
      </div>
    </ReportsLayout>
  )
}

export default PayReports
