import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Login from './pages/Login/Login'
import PayReports from './pages/PayReports/PayReports'
import UploadReport from './pages/UploadReport/UploadReport'

import './App.scss'

const App = function () {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/pay-reports">
            <PayReports />
          </Route>
          <Route path="/upload-report">
            <UploadReport />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
