const apiEndpoints = {
  LOGIN: {
    GET_TOKEN: 'api/token/',
    REFRESH_TOKEN: 'api/token/refresh/',
    RESET_PASSWORD: 'accounts/password_reset/',
  },
  REPORTES_DE_PAGO: {
    DESCARGA_REPORTES: 'api/reporte_de_pagos/download/',
    REPORTE: 'api/reporte_de_pagos/',
    SUBIR_REPORTE: 'api/reporte_de_pagos/upload/',
  },
}

export default apiEndpoints
