import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'

import logo from '../../assets/logo-enjoy.svg'

import './TopMenu.scss'

const { Header } = Layout

const TopMenu = function (props: any) {
  const history = useHistory()

  const logout = () => {
    sessionStorage.clear()
    history.replace('login')
  }

  return (
    <Header className="TopMenu">
      <img src={logo} alt="logo" />
      <Menu
        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        mode="horizontal"
        defaultSelectedKeys={[props.section]}
      >
        <Menu.Item key="UploadReport">
          <Link to="/upload-report">Carga Reporte Pago</Link>
        </Menu.Item>
        <Menu.Item key="PayReports">
          <Link to="/pay-reports">Reportes de Pagos</Link>
        </Menu.Item>
      </Menu>
      <Menu mode="horizontal" selectable={false}>
        <Menu.Item icon={<UserOutlined/>} key="users">
          {props.name}
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined/>} key="logout" onClick={logout} style={{ minWidth: 100}}>
          Salir
        </Menu.Item>
      </Menu>
    </Header>
  )
}

export default TopMenu
