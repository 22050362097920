import React from 'react'
import { Input } from 'antd'
import moment from 'moment'

import Chip from '../../components/Chip/Chip'
import compareAlphabetically from '../../helpers/compareAlphabetically'

moment.locale('es')

const states: any = {
  'Archivo inválido': {
    color: '#C10504',
    sortIndex: 4,
  },
  Error: {
    color: '#C10504',
    sortIndex: 4,
  },
  Pendiente: {
    color: '#FFC003',
    sortIndex: 1,
  },
  Procesando: {
    color: '#FFC003',
    sortIndex: 3,
  },
  Terminado: {
    color: '#75B04C',
    sortIndex: 5,
  },
  Validando: {
    color: '#FFC003',
    sortIndex: 2,
  },
}

const columns = (search: any) => [
  {
    dataIndex: 'fecha_creacion',
    render: (date: any) => moment(date).format('DD-MMM-YYYY HH:mm'),
    sorter: (a: any, b: any) => compareAlphabetically(a.fecha_creacion, b.fecha_creacion),
    title: <div>
      <div>Fecha</div>
      <Input onChange={(e) => search(e.target.value, 'fecha_creacion')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" style={{ zIndex: 234234 }} />
           </div>,
  },
  {
    dataIndex: 'usuario',
    sorter: (a: any, b: any) => compareAlphabetically(a.usuario, b.usuario),
    title: <div>
      <div>Usuario</div>
      <Input onChange={(e) => search(e.target.value, 'usuario')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'id',
    sorter: (a: any, b: any) => compareAlphabetically(a.id, b.id),
    title: <div>
      <div>Id</div>
      <Input onChange={(e) => search(e.target.value, 'id')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'nombre',
    sorter: (a: any, b: any) => compareAlphabetically(a.nombre, b.nombre),
    title: <div>
      <div>Documento</div>
      <Input onChange={(e) => search(e.target.value, 'nombre')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'total',
    sorter: (a: any, b: any) => compareAlphabetically(a.total, b.total),
    title: <div>
      <div>Total</div>
      <Input onChange={(e) => search(e.target.value, 'total')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'pendiente',
    sorter: (a: any, b: any) => compareAlphabetically(a.pendiente, b.pendiente),
    title: <div>
      <div>Pendientes</div>
      <Input onChange={(e) => search(e.target.value, 'pendiente')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'conciliadas',
    sorter: (a: any, b: any) => compareAlphabetically(a.conciliadas, b.conciliadas),
    title: <div>
      <div>Conciliadas</div>
      <Input onChange={(e) => search(e.target.value, 'conciliadas')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'no_conciliadas',
    sorter: (a: any, b: any) => compareAlphabetically(a.no_conciliadas, b.no_conciliadas),
    title: <div>
      <div>No Conciliadas</div>
      <Input onChange={(e) => search(e.target.value, 'no_conciliadas')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
  {
    dataIndex: 'estado',
    render: (state: any) => <Chip color={states[state].color}>{state}</Chip>,
    sorter: (a: any, b: any) => states[a.estado].sortIndex - states[b.estado].sortIndex,
    title: <div>
      <div>Estado</div>
      <Input onChange={(e) => search(e.target.value, 'estado')} onClick={(e) => e.stopPropagation()} placeholder="Buscar" />
           </div>,
  },
]

export { columns }
