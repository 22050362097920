import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'

import Footer from '../../components/Footer/Footer'
import TopMenu from '../../components/TopMenu/TopMenu'

import './ReportsLayout.scss'

const { Content } = Layout

const ReportsLayout = function (props: any) {
  const [name, setName] = useState<string>('')

  function setNameEffect() {
    setName(`${sessionStorage.getItem('nombre')} ${sessionStorage.getItem('apellido')}`)
  }

  useEffect(setNameEffect, [sessionStorage.getItem('nombre'), sessionStorage.getItem('apellido')])

  return (
    <div className="ReportsLayout">
      <Layout>
        <TopMenu name={name} section={props.section} />
        <Content>{props.children}</Content>
        <Footer />
      </Layout>
    </div>
  )
}

export default ReportsLayout
