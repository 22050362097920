import { message } from 'antd'
import axios from 'axios'

import Auth from '../services/auth'

const axiosApi = axios.create()

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async (config) => {
    if (sessionStorage.getItem('access_token')) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

// Response interceptor for API calls
axiosApi.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      const refresh_token = await Auth.refresh_token()
      if (refresh_token.status === 200) {
        error.config.headers = { ...error.config.headers, Authorization: `Bearer ${sessionStorage.getItem('access_token')}` }
        return await axios.request(error.config)
      }
      message.error('Tu sesión ha expirado. Serás redireccionado al login')
      setTimeout(() => { window.location.href = '/' }, 3000)
    }
    return Promise.reject(error)
  },
)

export default axiosApi
