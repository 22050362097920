import React from 'react'
import { Layout } from 'antd'

import './Footer.scss'

const { Footer: AntFooter } = Layout

const Footer = function () {
  return (
    <AntFooter className="Footer">© ENJOY ©2018 TODOS LOS DERECHOS RESERVADOS</AntFooter>
  )
}

export default Footer
