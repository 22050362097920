import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button, Divider, Form, Input, message,
} from 'antd'

import logo from '../../assets/logo-enjoy.svg'
import apiEndpoints from '../../config/apiEndpoints'
import Auth from '../../services/auth'

import './Login.scss'

const Login = function () {
  const history = useHistory()
  const onFinish = async (values: any) => {
    if (!values.email) {
      message.error('Ingresa un email')
    } else if (!values.password) {
      message.error('Ingresa la contraseña')
    } else {
      return Auth.get_token({
        password: values.password,
        username: values.email,
      }).then((response: any) => {
        if (response.status === 200) {
          history.push('/upload-report')
        } else {
          message.error('No estás autorizado.')
        }
        return response
      })
    }
  }

  return (
    <div className="Login">
      <div className="left">
        <img src={logo} alt="logo" />
        <span className="title">
          PLATAFORMA
          <br />
          CONCILIACIÓN
        </span>
      </div>
      <Divider type="vertical" className="divider" />
      <div className="right">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            labelCol={{ span: 24 }}
            label="Email"
            name="email"
            rules={[{ message: '' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            label="Contraseña"
            name="password"
            rules={[{ message: '' }]}
          >
            <Input.Password visibilityToggle={false} />
          </Form.Item>
          <a className="forgot-password" href={`${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.LOGIN.RESET_PASSWORD}`}>Olvidé mi contraseña</a>
          <Form.Item>
            <Button className="signin-button" type="primary" htmlType="submit">
              Ingresar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
