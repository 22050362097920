import React from 'react'

import './Chip.scss'

const Chip = function (props: any) {
  const color = props.color ? props.color : '#FFC003'

  return (
    <div className="Chip" style={{ backgroundColor: color }}>{props.children}</div>
  )
}

export default Chip
