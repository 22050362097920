import React, { useState } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Button,message, Upload } from 'antd'

import apiEndpoints from '../../config/apiEndpoints'
import axios from '../../config/axiosApiInterceptor'
import ReportsLayout from '../../layouts/ReportsLayout/ReportsLayout'

import './UploadReport.scss'

const { Dragger } = Upload

const UploadReport = function () {
  const [userId, setUserId] = useState<number>()

  async function validateFile(file: any) {
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error(`${file.name} no es .xlsx`)
      return false
    }
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.REPORTES_DE_PAGO.REPORTE}`, {
      nombre: file.name,
      usuario: `${sessionStorage.getItem('nombre')} ${sessionStorage.getItem('apellido')}`,
    }, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    })
      .then((response) => {
        setUserId(response.data.id)
        message.info(`Subiendo ${file.name}`)
        return file
      })
      .catch((error) => {
        if (error.response.data.nombre) {
          message.error(`${error.response.data.nombre[0]}`)
        }
        throw new Error()
      })
  }

  const draggerProps = {
    action: `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.REPORTES_DE_PAGO.SUBIR_REPORTE}${userId}/`,
    beforeUpload: validateFile,
    headers: { Authorization: `Bearer ${sessionStorage.getItem('access_token')}` },
    multiple: true,
    name: 'file',
    onChange(info: any) {
      const { status } = info.file
      if (status === 'done') {
        message.success(`${info.file.name} subido.`)
      } else if (status === 'error') {
        message.error(`Falló subida de ${info.file.name}.`)
      }
      setUserId(undefined)
    },
    showUploadList: false,
  }

  return (
    <ReportsLayout section="UploadReport">
      <h1>Carga Reporte Pago</h1>
      <span>Sube un archivo que contenga el registro de los datos</span>
      <Dragger className="dragger" {...draggerProps}>
        <p className="ant-upload-drag-icon dragger-icon">
          <CloudUploadOutlined />
        </p>
        <p>
          Arrastra y suelta aquí tu archivo
          <br />
          o
        </p>
        <p>
          <Button id="find-file" type="primary" ghost>
            Buscar un archivo
          </Button>
        </p>
        <p>Formato aceptado .xlsx</p>
      </Dragger>
      <div className="upload-button">
        <Button
          type="primary"
          onClick={() => {
            const uploadButton = document.getElementById('find-file') as any
            uploadButton.click()
          }}
        >
          Cargar
        </Button>
      </div>
    </ReportsLayout>
  )
}

export default UploadReport
