import axios from 'axios'

import apiEndpoints from '../config/apiEndpoints'

export default class Auth {
  static async get_token(loginData: any) {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.LOGIN.GET_TOKEN}`,
      loginData,
    ).then((response) => {
      if (response.data === undefined) {
        throw new Error('Credential not provider')
      }
      sessionStorage.setItem('access_token', response.data.access)
      sessionStorage.setItem('refresh_token', response.data.refresh)
      sessionStorage.setItem('nombre', response.data.first_name)
      sessionStorage.setItem('apellido', response.data.last_name)
      return response
    }).catch((error) => error)
  }

  static async refresh_token() {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${apiEndpoints.LOGIN.REFRESH_TOKEN}`,
      { refresh: sessionStorage.getItem('refresh_token') },
    ).then((response) => {
      sessionStorage.setItem('access_token', response.data.access)
      return response
    }).catch((error) => error)
  }
}
